<template>
  <el-dialog class="lightspot-dialog" title="加入亮点库" :visible.sync="visible" :lightspotDialogVisible="lightspotDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="40%">
    <el-form ref="lightspotForm" :rules="rules" :model="lightspotForm" label-width="6.25vw">
      <el-row>
        <el-col :span="24">
          <el-form-item label="加入原因：" ><!--prop="reason"-->
            <el-input type="textarea" :rows="4" placeholder="请输入加入原因" :maxlength="500" v-model="lightspotForm.reason" resize="none"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="lightspotButtonloading" @click="handleClose">取 消</el-button>
      <el-button :loading="lightspotButtonloading" type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'lightspotDialog',
  props: {
    lightspotDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      visible: false,// 弹窗显隐标识
      lightspotForm: {
        reason: ""
      },//表单数据
      rules: {
        reason: [{ required: true, message: "请输入加入原因", trigger: "blur" }],
      },//校验规则
      lightspotButtonloading: false, //按钮loading
    }
  },
  watch: {
    lightspotDialogVisible(val){
      this.visible = val
    }
  },
  methods: {
    handleClose(){
      this.lightspotForm = {
        reason: ""
      }
      this.lightspotButtonloading = false
      this.$refs.lightspotForm.clearValidate()
      this.$emit("returnFun",{ flag: false })
    },
    handleSubmit(){
      this.$refs.lightspotForm.validate((valid) => {
        if (valid) {
          this.lightspotButtonloading = true
          this.$emit("returnFun",{ reason: this.lightspotForm.reason, flag: true })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer{
  /deep/.el-button--primary{
    width: auto;
  }
}
</style>