<template>
  <div class="contain" v-loading.fullscreen.lock="fullPageLoading">
    <div class="header-title">
      <!-- <span>{{ pageTitle }}</span>
      <el-button class="return-button" @click="onCancel" v-if="type != 'view'">返回</el-button> -->
      <el-page-header @back="onCancel" :content="pageTitle">
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
      <div class="check-select">
        <el-checkbox-group
            v-model="selectCheck"
            @change="handleListChange"
            v-if="
            (taskInfo.orderStatus && taskInfo.orderStatus != '1') ||
            (!taskInfo.orderStatus &&
              (jumpType == 'company' || jumpType == 'headquarters'))
          "
        >
          <el-checkbox :label="'1'">显示历史评分</el-checkbox>
          <el-checkbox
              :label="'2'"
              v-if="
              taskInfo.orderStatus &&
              taskInfo.orderStatus != '2' &&
              taskInfo.orderStatus != '3'
            "
          >仅显示整改项
          </el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="form">
      <div class="view-body">
        <div class="view-body-title">{{ taskInfo.templateName }}</div>
        <div class="view-body-remark">{{ taskInfo.description }}</div>
        <div class="dashed-line"></div>
        <div class="view-body-content-choose">
          <div class="label">督导内容：</div>
          <div class="item">
            <el-select
                v-model="projectContent"
                placeholder="请选择"
                @change="handleSelectSupervise"
                popper-class="scoreSelection"
            >
              <el-option
                  :class="{ finishOptions: item.isFinish }"
                  v-for="item in projectContentOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="view-body-content-list">
          <div
              class="view-body-content-item"
              v-for="(item, index) in projectContentList"
              :key="item.id"
          >
            <div class="item-title">{{ item.projectName }}</div>
            <div
                class="item-options"
                v-for="(info, i) in item.optionsList"
                :key="info.id"
            >
              <div class="options-question-buttons">
                <div class="options-question" style="line-height: 1.04167vw">
                  {{ info.optionsNum }}、{{ info.projectName }}
                </div>
                <el-checkbox
                    v-show="isEdit"
                    class="options-rectification"
                    v-model="info.chooseRectify"
                    v-if="jumpType == 'company' || jumpType == 'headquarters'"
                    @change="handleCheckAllChangeSales"
                >整改
                </el-checkbox
                >
                <div
                    class="options-buttons"
                    v-show="info.isTotalScore"
                    v-if="jumpType == 'company' || jumpType == 'headquarters'"
                >
                  <el-button
                      v-show="isEdit"
                      type="primary"
                      :disabled="info.isLibrary"
                      @click="handleLightspot(info, i, index)"
                  >{{ info.isLibrary ? "已加入" : "亮点库" }}
                  </el-button
                  >
                  <div class="lightspot-tip" v-if="!info.isLibrary"  v-show="isEdit">
                    点击加入亮点库
                  </div>
                </div>
              </div>
              <el-radio-group
                  class="options-list"
                  v-model="info.chooseOptions"
                  :disabled="!isEdit"
                  @change="(val) => handleOptionsChange(val, info, i, index)"
              >
                <el-radio
                    class="options-item choose"
                    :label="optionInfo.id"
                    v-for="optionInfo in info.options"
                    :key="optionInfo.id"
                ><span class="text">{{ optionInfo.projectName }}</span
                ><span class="score"
                ><span v-if="optionInfo.scoreType == '2'"></span
                >{{ optionInfo.score }}分</span
                ></el-radio
                >
              </el-radio-group>
              <div class="options-files" v-show="isEdit">
                <el-upload
                    class="avatar-uploader"
                    :action="uploadFileUrl"
                    :headers="uploadHeader"
                    :show-file-list="false"
                    :on-error="handleFileError"
                    :on-success="
                    (res, file) =>
                      handleAvatarSuccess(res, file, info, i, index)
                  "
                    :before-upload="
                    (file) => beforeAvatarUpload(file, info, i, index)
                  "
                >
                  <div class="upload-button">
                    <span class="upload-required" v-if="info.isRequired == '1'"
                    >*</span
                    >
                    <img :src="fileAddIcon" alt=""/>
                    <span>{{
                        info.attachmentList && info.attachmentList.length > 0
                            ? "继续添加"
                            : "添加附件"
                      }}</span>
                    <el-popover
                        placement="top-start"
                        title="提示"
                        width="200"
                        trigger="hover"
                        :content="info.helpDescription"
                        popper-class="poper-common"
                        v-if="info.helpDescription"
                    >
                      <i
                          class="el-icon-warning-outline"
                          slot="reference"
                          v-if="info.helpDescription"
                      ></i>
                    </el-popover>
                    <span
                        style="margin-left: 1.04167vw; color: #c6000b"
                        v-if="info.fileTip"
                    >{{ info.fileTip }}</span
                    >
                  </div>
                </el-upload>
                <div class="options-file-list">
                  <div
                      class="options-file-item"
                      v-for="(fileInfo, fileIndex) in info.attachmentList"
                      :key="fileInfo.id"
                  >
                    <img :src="fileIcon" alt=""/>
                    <span>{{ fileInfo.attachmentName }}</span>
                    <div class="handle-icon">
                      <img
                          :class="{ canClick: fileInfo.attachmentId }"
                          :src="
                          fileInfo.attachmentId
                            ? onlineViewIcon
                            : onlineViewDisabledIcon
                        "
                          alt=""
                          @click="handleAttachFileFun('view', fileInfo)"
                      />
                      <img
                          :class="{ canClick: fileInfo.attachmentId }"
                          :src="
                          fileInfo.attachmentId
                            ? fileDownloadIcon
                            : fileDownloadDisabledIcon
                        "
                          alt=""
                          @click="handleAttachFileFun('download', fileInfo)"
                      />
                      <!-- <img class="canClick" :src="filePrintIcon" alt="" @click="handleAttachFileFun('print',fileInfo)"> -->
                      <img
                          :class="{
                          canClick:
                            (jumpType == 'headquarters' &&
                              fileInfo.level == '1') ||
                            (jumpType == 'company' && fileInfo.level == '2') ||
                            (jumpType == 'school' && fileInfo.level == '3'),
                        }"
                          :src="
                          (jumpType == 'headquarters' &&
                            fileInfo.level == '1') ||
                          (jumpType == 'company' && fileInfo.level == '2') ||
                          (jumpType == 'school' && fileInfo.level == '3')
                            ? fileRemoveIcon
                            : fileRemoveDisabledIcon
                        "
                          alt=""
                          @click="
                          handleAttachFileFun(
                            'del',
                            fileInfo,
                            fileIndex,
                            i,
                            index
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                  v-show="isEdit"
                  class="options-comment"
                  :class="{ getHeight: info.commentVisible }"
              >
                <el-popover
                    placement="bottom-start"
                    width="500"
                    trigger="manual"
                    v-model="info.commentVisible"
                    :popper-options="{ boundariesElement: 'body' }"
                    popper-class="poper-common"
                >
                  <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="请输入内容"
                      v-model="info.comment"
                      :maxlength="200"
                      resize="none"
                      @change="(val) => handleCommentChange(val, info, i, index)"
                  ></el-input>
                  <div
                      class="comment-text"
                      slot="reference"
                      @click="info.commentVisible = !info.commentVisible"
                  >
                    <img :src="editCommentIcon" alt=""/><span>写评语</span>
                  </div>
                </el-popover>
              </div>
              <div class="options-score-list" v-show="info.isShowScoreList">
                <div
                    class="score-text"
                    @click="handleScoreShow(info, i, index)"
                >
                  <span>历史评分</span
                  ><img
                    :class="{ changeArraw: info.isChangeShow }"
                    :src="arrowIcon"
                    alt=""
                />
                </div>
                <l-table
                    v-show="!info.isChangeShow"
                    :columns="columns"
                    :dataSource="info.supOrderTaskRecordList"
                    :pagination="false"
                    :loading="tableLoading"
                    :showXh="true"
                    :height="'calc(100% - 2.91667vw)'"
                    :autoHeight="false"
                >
                  <el-table-column
                      prop="createTime"
                      label="时间"
                      align="center"
                      width="150"
                      slot="createTime"
                  ></el-table-column>
                  <el-table-column
                      prop="nodeName"
                      label="操作"
                      align="center"
                      width="150"
                      slot="nodeName"
                  >
                    <template slot-scope="scope">
                      <span>{{
                          scope.row.nodeName == "1"
                              ? "园校评分"
                              : scope.row.nodeName == "2"
                                  ? "分公司评分"
                                  : scope.row.nodeName == "3"
                                      ? "总部评分"
                                      : ""
                        }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="operate"
                      label="操作者"
                      align="center"
                      width="150"
                      slot="operate"
                  ></el-table-column>
                  <el-table-column
                      prop="score"
                      label="评分"
                      align="center"
                      width="100"
                      slot="score"
                  ></el-table-column>
                  <el-table-column
                      prop="comment"
                      label="评分意见"
                      align="center"
                      min-width="300"
                      slot="comment"
                  ></el-table-column>
                  <el-table-column
                      prop="attachmentList"
                      label="附件"
                      align="center"
                      min-width="250"
                      slot="attachmentList"
                  >
                    <template slot-scope="scope">
                      <el-button
                          class="table-handle file"
                          @click="downloadScoreFile(item)"
                          type="text"
                          v-for="item in scope.row.attachmentList"
                          :key="item.attachmentId"
                      >{{ item.attachmentName }}
                      </el-button
                      >
                    </template>
                  </el-table-column>
                </l-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-btn" v-show="isEdit">
      <el-button class="cancel" :loading="buttonLoading" @click="onCancel"
      >取消
      </el-button
      >
      <el-button
          class="return"
          :loading="buttonLoading"
          @click="onReturn"
          v-if="jumpType != 'school'"
      >驳回园校
      </el-button
      >
      <el-button class="submit" :loading="buttonLoading" @click="onSubmit"
      >提交
      </el-button
      >
      <el-popover
          placement="top"
          width="280"
          v-model="savePopover"
          @show="handleSavePopoverShow"
      >
        <div class="score-save-popover-body">
          <img :src="meesageWarningIcon" alt=""/>
          <div class="score-save-popover-text">
            所有题目都已经完成，您可以点击 提交，是否要继续保存？
          </div>
        </div>
        <div class="score-save-popover-footer">
          <el-button @click="onSubmit">提交</el-button>
          <el-button type="primary" @click="onSave">保存</el-button>
        </div>
        <el-button class="save" :loading="buttonLoading" slot="reference"
        >保存
        </el-button
        >
      </el-popover>
    </div>
    <el-image
        style="width: 0; height: 0"
        ref="viewImg"
        :src="viewUrl"
        :preview-src-list="viewUrlList"
    ></el-image>
    <onlinePreview
        :onlinePreviewVisible="onlinePreviewVisible"
        :type="fileAttachment.previewType"
        @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
        :url="fileAttachment.url"
        :title="fileAttachment.title"
    />
    <lightspot-dialog
        ref="lightspotDialog"
        @returnFun="lightspotReturnFun"
        :lightspotDialogVisible="lightspotDialogVisible"
    ></lightspot-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getToken} from "@/utils/auth";
import downloadFile from "@/utils/downloadFile.js";
import fileAddIcon from "@/assets/img/file-add-icon.png";
import fileIcon from "@/assets/img/file-flag-icon.png";
import onlineViewIcon from "@/assets/img/online-view-icon.png";
import onlineViewDisabledIcon from "@/assets/img/online-view-disabled-icon.png";
import fileDownloadIcon from "@/assets/img/file-download-icon.png";
import fileDownloadDisabledIcon from "@/assets/img/file-download-disabled-icon.png";
import filePrintIcon from "@/assets/img/file-print-icon.png";
import filePrintDisabledIcon from "@/assets/img/file-print-disabled-icon.png";
import fileRemoveIcon from "@/assets/img/file-remove-icon.png";
import fileRemoveDisabledIcon from "@/assets/img/file-remove-disabled-icon.png";
import editCommentIcon from "@/assets/img/edit-comment-icon.png";
import arrowIcon from "@/assets/img/arrow-icon.png";
import closeIcon from "@/assets/img/icon_close.png";
import meesageWarningIcon from "@/assets/img/message-warning-icon.png";
import LTable from "@/components/tableComponent/index.vue";
import onlinePreview from "@/components/onlinePreview/index.vue";
import LightspotDialog from "./components/lightspotDialog.vue";

export default {
  name: "taskScoreDetail",
  components: {LTable, onlinePreview, LightspotDialog},
  data() {
    return {
      type: "", //编辑类型
      jumpType: "", //跳转类型
      id: null, //待办任务id
      pageTitle: "评分", //页面标题

      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉

      userInfo: {}, //登录人信息
      taskInfo: {}, //模板信息
      projectContent: "", //督导内容下拉选中项
      projectContentOptions: [], //督导内容下拉list
      projectContentList: [], //督导内容与选项list
      requestData: [], //原始请求数据

      buttonLoading: false, //按钮loading

      tableLoading: false, // tableloading
      columns: [
        {
          label: "时间",
          slotName: "createTime",
        },
        {
          label: "操作",
          slotName: "nodeName",
        },
        {
          label: "操作者",
          slotName: "operate",
        },
        {
          label: "评分",
          slotName: "score",
        },
        {
          label: "评分意见",
          slotName: "comment",
        },
        {
          label: "附件",
          slotName: "attachmentList",
        },
      ],

      selectCheck: [], //选择项
      uploadFileUrl: "/webForm/dataDirectory/data-attachment/uploadFile",
      uploadHeader: {token: this.getToken()},

      onlinePreviewVisible: false, //文件预览标识
      fileAttachment: {}, //预览文件对象

      viewUrl: "", // 附件图片预览
      viewUrlList: [], // 附件图片预览list

      lightspotDialogVisible: false, //加入亮点库
      lightspotData: {}, //加入亮点库对象

      fullPageLoading: false, //整体页面loading
      savePopover: false, //保存的popover标识

      fileAddIcon, //附件添加icon
      fileIcon, //附件icon
      onlineViewIcon, //在线预览icon
      onlineViewDisabledIcon, //在线预览禁用icon
      fileDownloadIcon, //下载icon
      fileDownloadDisabledIcon, //下载禁用icon
      filePrintIcon, //打印icon
      filePrintDisabledIcon, //打印禁用icon
      fileRemoveIcon, //附件删除icon
      fileRemoveDisabledIcon, //附件删除禁用icon
      editCommentIcon, //写评语icon
      arrowIcon, //箭头icon
      closeIcon, //关闭icon
      meesageWarningIcon, //信息提醒icon
      updateVal: false, //勾选整改change按钮的val
      updateValArr: [], //勾选整改change按钮的val
      operationType:"",//操作类型：1：园校查看分数、2：分公司查看分数、3：总部查看分数
      isEdit: false,//是否编辑
    };
  },
  computed: {
    ...mapState({
      fileTypeList: (state) => state.common.fileTypeList,
      postOptions: (state) => state.common.postList,
    }),
  },
  //路由进入前改变title
  beforeRouterEnter(to, from, next) {
    if (to.query.jumpType == "company") {
      to.meta.title = "分公司评分";
    } else if (to.query.jumpType == "school") {
      to.meta.title = "园校评分";
    } else {
      to.meta.title = "总部评分";
    }
    next();
  },
  async created() {
    this.jumpType = this.$route.query.jumpType;
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    this.isEdit = this.$route.query.isEdit ==="true"?true:false;
    this.operationType = this.$route.query.nodeName;
    console.log("isEdit===>"+this.isEdit);
    console.log(this.$route.query);
    await this.getSuperviseSelection();
    this.getTaskScoreInfo();

    let userInfo = sessionStorage.getItem("userInfo")
        ? JSON.parse(sessionStorage.getItem("userInfo"))
        : {};
    this.userInfo = userInfo;
  },
  watch: {
    fileTypeList: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTaskScoreInfo(this.$route.query.id);
        }
      },
      deep: true,
    },
  },
  methods: {
    getToken,
    //整改
    handleCheckAllChangeSales(val) {
      this.updateVal = val;
      this.updateValArr = [];
      this.projectContentList.filter((item) => {
        item.optionsList.filter((val) => {
          if (val.chooseRectify) this.updateValArr.push(val.chooseRectify);
        });
      });
    },

    //获取督导内容下拉
    async getSuperviseSelection() {
      await this.$api
          .getAgencyTaskScoreSuperviseInfo(this.id)
          .then((res) => {
            if (res.data.code == 0) {
              if (res.data.data) {
                res.data.data.map((item) => {
                  item.isFinish = false;
                });
                let list = []
                    .concat([{id: "", name: "全部", isFinish: false}])
                    .concat(res.data.data);
                this.projectContentOptions = list;
              } else {
                this.projectContentOptions = [];
              }
              // this.projectContent = res.data.data && res.data.data.length > 0 ? res.data.data[0].id : ''
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
          });
    },
    //获取任务评分信息
    getTaskScoreInfo(id) {
      this.$api
          .getAgencyTaskScoreInfo(id ? id : this.id,{operationType:this.operationType})
          .then((res) => {
            console.log("res======>",res);
            if (res.data.code == 0) {
              let resultInfo = res.data.data;
              this.taskInfo = {
                templateId: resultInfo.templateId,
                templateName: resultInfo.templateName,
                description: resultInfo.description,
                orderStatus: resultInfo.orderStatus,
              };
              if (
                  resultInfo.orderStatus &&
                  resultInfo.orderStatus !== "1" &&
                  resultInfo.orderStatus !== "2" &&
                  resultInfo.orderStatus !== "3"
              ) {
                this.selectCheck = ["2"];
              }
              resultInfo.oneLevelList.map((item, index) => {
                item.firstLevelIndex = index;
                if (item.fourLevelProject && item.fourLevelProject.length > 0) {
                  item.fourLevelProject.map((info, i) => {
                    info.firstLevelIndex = index;
                    info.fourLevelIndex = i;
                    if (info.options && info.options.length > 0) {
                      info.options.map((optionInfo, j) => {
                        optionInfo.firstLevelIndex = index;
                        optionInfo.fourLevelIndex = i;
                        optionInfo.optionIndex = j;
                      });
                    }
                  });
                }
              });
              let list = JSON.parse(JSON.stringify(resultInfo.oneLevelList));
              console.log("list======>",list);
              let topicNum = 1;
              list.map((item) => {
                if (item.fourLevelProject && item.fourLevelProject.length > 0) {
                  item.fourLevelProject.map((info) => {
                    info.optionsNum = topicNum;
                    info.isShowScoreList = false;
                    info.isRectify = info.isRectify
                        ? info.isRectify == "1"
                            ? true
                            : false
                        : false;
                    info.chooseRectify = false;
                    if (this.jumpType == "company") {
                      info.isLibrary =
                          info.isLibrary == "12" || info.isLibrary == "22"
                              ? true
                              : false;
                    } else if (this.jumpType == "headquarters") {
                      info.isLibrary =
                          info.isLibrary == "11" || info.isLibrary == "22"
                              ? true
                              : false;
                    }
                    info.isTotalScore = false;
                    info.commentVisible = false;
                    info.isChangeShow = false;
                    info.chooseOptions = "";
                    info.selectOptionsScore = "";
                    let uploadTipString = [];
                    if (info.attachType) {
                      let attachTypeList = info.attachType
                          .split(",")
                          .filter((info) => {
                            return Number(info) > 5;
                          });
                      let isFileType = "";
                      let isVideoType = "";
                      if (info.attachType.indexOf("2") != -1) {
                        isFileType = "2";
                      } else if (
                          info.attachType.indexOf("1") != -1 &&
                          info.attachType.indexOf("2") == -1
                      ) {
                        isFileType = "1";
                      }
                      if (info.attachType.indexOf("5") != -1) {
                        isVideoType = "5";
                      } else if (
                          info.attachType.indexOf("4") != -1 &&
                          info.attachType.indexOf("5") == -1
                      ) {
                        isVideoType = "4";
                      } else if (
                          info.attachType.indexOf("3") != -1 &&
                          info.attachType.indexOf("4") == -1 &&
                          info.attachType.indexOf("5") == -1
                      ) {
                        isVideoType = "3";
                      }
                      if (isFileType) {
                        attachTypeList.push(isFileType);
                      }
                      if (isVideoType) {
                        attachTypeList.push(isVideoType);
                      }
                      if (attachTypeList.length > 0) {
                        attachTypeList.map((attachTypeInfo) => {
                          let attachInfo = this.fileTypeList.find((file) => {
                            return file.value == attachTypeInfo;
                          });
                          console.log(attachInfo);
                          if (attachInfo) {
                            uploadTipString.push(attachInfo.name);
                          }
                        });
                        info.fileTip =
                            "上传文件限制为：" + uploadTipString.join(",") + "。";
                        info.fileTypeList = attachTypeList;
                      } else {
                        info.fileTip = "";
                        info.fileTypeList = [];
                      }
                    }
                    topicNum++;
                    if (info.options && info.options.length > 0) {
                      let chooseInfo = {};
                      info.options.map((optionInfo) => {
                        optionInfo.projectName =
                            optionInfo.projectName.substring(0,optionInfo.projectName.length-2);
                        if (
                            resultInfo.orderStatus &&
                            resultInfo.orderStatus !== "1" &&
                            resultInfo.orderStatus !== "2" &&
                            resultInfo.orderStatus !== "3"
                        ) {
                          if (
                              optionInfo.isCheck === "1" &&
                              (!info.isRectify ||
                                  (info.isRectify && info.tmpIsRectify == "1"))
                          ) {
                            info.chooseOptions = optionInfo.id;
                            info.selectOptionsScore = optionInfo.score;
                            chooseInfo = optionInfo;
                          }
                        } else if (resultInfo.orderStatus || !resultInfo.orderStatus) {
                          if (optionInfo.isCheck === "1") {
                            info.chooseOptions = optionInfo.id;
                            info.selectOptionsScore = optionInfo.score;
                            chooseInfo = optionInfo;
                          }
                        }
                      });
                      info.maxScore = Number(info.score);
                      if (
                          chooseInfo.score &&
                          Number(chooseInfo.score) === Number(info.score) &&
                          (!info.isRectify ||
                              (info.isRectify && info.tmpIsRectify == "1"))
                      ) {
                        info.isTotalScore = true;
                      }
                    }
                  });
                }
                item.optionsList = item.fourLevelProject;
                let flag = item.fourLevelProject.every((optionsInfo) => {
                  return optionsInfo.chooseOptions !== "";
                });
                if (flag) {
                  let chooseIndex = this.projectContentOptions.findIndex(
                      (chooseInfo) => {
                        return chooseInfo.id == item.id;
                      }
                  );
                  if (chooseIndex != -1) {
                    let info = JSON.parse(
                        JSON.stringify(this.projectContentOptions[chooseIndex])
                    );
                    info.isFinish = true;
                    this.$set(this.projectContentOptions, chooseIndex, info);
                  }
                }
              });
              console.log("resultInfo",resultInfo);
              this.requestData = JSON.parse(JSON.stringify(list));
              if (
                  resultInfo.orderStatus &&
                  resultInfo.orderStatus !== "1" &&
                  resultInfo.orderStatus !== "2" &&
                  resultInfo.orderStatus !== "3"
              ) {
                this.getShowData();
              } else {
                this.projectContentList = list;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
          });
    },
    //上传失败
    handleFileError(err, file, fileList) {
      this.fullPageLoading = false;
    },
    //上传成功
    handleAvatarSuccess(res, file, optionInfo, optionIndex, listIndex) {
      this.fullPageLoading = false;
      if (res.code === "0") {
        let fileInfo = {
          attachmentName: file.name,
          attachmentType: file.name
              .substr(file.name.lastIndexOf(".") + 1, file.name.length)
              .toLowerCase(),
          path: res.data.fileUrl,
          pdfPath: res.data.pdfUrl ? res.data.pdfUrl : "",
          orderId: this.projectContentList[listIndex].orderId,
          taskId: this.projectContentList[listIndex].taskId,
          projectId: optionInfo.id,
          level:
              this.jumpType == "headquarters"
                  ? "1"
                  : this.jumpType == "company"
                      ? "2"
                      : this.jumpType == "school"
                          ? "3"
                          : "",
        };
        let resultInfo = JSON.parse(JSON.stringify(optionInfo));
        resultInfo.attachmentList = resultInfo.attachmentList
            ? resultInfo.attachmentList.concat([{...fileInfo}])
            : [{...fileInfo}];
        this.$nextTick(() => {
          console.log(this.projectContentList);
        });
        this.$set(
            this.projectContentList[listIndex].optionsList,
            optionIndex,
            resultInfo
        );
        this.$set(
            this.requestData[resultInfo.firstLevelIndex].optionsList,
            resultInfo.fourLevelIndex,
            resultInfo
        );
      } else {
        this.$message.error(res.msg);
      }
    },
    //上传前
    async beforeAvatarUpload(file, optionInfo, optionIndex, listIndex) {
      return new Promise(async (resolve, reject) => {
        if (optionInfo.attachType) {
          let limitImg = true;
          let limitVideo = true;
          let limitFile = true;
          let limitPPTType = true;
          let limitWORDType = true;
          let limitEXCELType = true;
          let limitPDFType = true;
          let imgSingle = optionInfo.fileTypeList.findIndex((file) => {
            return file == "1";
          });
          let imgMultiple = optionInfo.fileTypeList.findIndex((file) => {
            return file == "2";
          });
          let videoOne = optionInfo.fileTypeList.findIndex((file) => {
            return file == "3";
          });
          let videoThree = optionInfo.fileTypeList.findIndex((file) => {
            return file == "4";
          });
          let videoFifteen = optionInfo.fileTypeList.findIndex((file) => {
            return file == "5";
          });
          let isPPT = optionInfo.fileTypeList.findIndex((file) => {
            return file == "6";
          });
          let isWORD = optionInfo.fileTypeList.findIndex((file) => {
            return file == "7";
          });
          let isEXCEL = optionInfo.fileTypeList.findIndex((file) => {
            return file == "8";
          });
          let isPDF = optionInfo.fileTypeList.findIndex((file) => {
            return file == "9";
          });
          let type = file.name
              .substr(file.name.lastIndexOf(".") + 1, file.name.length)
              .toLowerCase();
          let isimg = ["png", "jpg", "jpeg", "gif"].findIndex((imgInfo) => {
            return imgInfo == type;
          });
          let isvideo = ["mp4", "mov", "rmvb", "flv", "avi", "mpg"].findIndex(
              (videoInfo) => {
                return videoInfo == type;
              }
          );
          let isPPTType =
              isPPT != -1
                  ? ["ppt", "pptx"].findIndex((PPTInfo) => {
                    return PPTInfo == type;
                  })
                  : -1;
          let isWORDType =
              isWORD != -1
                  ? ["doc", "docx"].findIndex((WORDInfo) => {
                    return WORDInfo == type;
                  })
                  : -1;
          let isEXCELType =
              isEXCEL != -1
                  ? ["xls", "xlsx"].findIndex((EXCELInfo) => {
                    return EXCELInfo == type;
                  })
                  : -1;
          let isPDFType =
              isPDF != -1
                  ? ["pdf"].findIndex((PDFInfo) => {
                    return PDFInfo == type;
                  })
                  : -1;
          let duration;
          if (isvideo != -1) {
            duration = await this.getVideoTime(file);
            console.log(duration);
          }
          if (imgSingle != -1) {
            limitImg =
                (optionInfo.attachmentList &&
                    optionInfo.attachmentList.length == 0) ||
                !optionInfo.attachmentList;
          }
          if (imgMultiple != -1) {
            limitImg =
                (optionInfo.attachmentList &&
                    optionInfo.attachmentList.length < 9) ||
                !optionInfo.attachmentList;
          }
          if (videoOne != -1 && videoThree == -1 && videoFifteen == -1) {
            limitVideo = duration && duration <= 60;
          }
          if (videoThree != -1 && videoFifteen == -1) {
            limitVideo = duration && duration <= 3 * 60;
          }
          if (videoFifteen != -1) {
            limitVideo = duration && duration <= 15 * 60;
          }

          limitPPTType =
              (isPPT != -1 && isPPTType != -1) || isPPT == -1 ? true : false;
          limitWORDType =
              (isWORD != -1 && isWORDType != -1) || isWORD == -1 ? true : false;
          limitEXCELType =
              (isEXCEL != -1 && isEXCELType != -1) || isEXCEL == -1
                  ? true
                  : false;
          limitPDFType =
              (isPDF != -1 && isPDFType != -1) || isPDF == -1 ? true : false;
          if (limitPPTType && limitWORDType && limitEXCELType && limitPDFType) {
            limitFile = true;
          } else {
            limitFile = false;
          }

          if (isimg != -1) {
            if (imgSingle != -1) {
              if (!limitImg) {
                this.$message.error("上传图片只能上传一张!");
              }
              if (limitImg && isimg != -1) {
                this.fullPageLoading = true;
                resolve(true);
              } else {
                reject(false);
              }
            } else if (imgMultiple != -1) {
              if (!limitImg) {
                this.$message.error("上传图片只能上传九张!");
              }
              if (limitImg && isimg != -1) {
                this.fullPageLoading = true;
                resolve(true);
              } else {
                reject(false);
              }
            } else {
              this.$message.error(
                  "上传失败，请上传 " +
                  (videoOne != -1 || videoThree != -1 || videoFifteen != -1
                      ? "mp4, mov, rmvb, flv, avi, mpg, "
                      : "") +
                  (isPPT != -1 ? "ppt, pptx, " : "") +
                  (isWORD != -1 ? "doc, docx, " : "") +
                  (isEXCEL != -1 ? "xls, xlsx, " : "") +
                  (isPDF != -1 ? "pdf " : "") +
                  "格式文件"
              );
              reject(false);
            }
          } else if (isvideo != -1) {
            if (videoOne != -1 && videoThree == -1 && videoFifteen == -1) {
              if (!limitVideo) {
                this.$message.error("上传视频时长只能是一分钟以内!");
              }
              // if (!isvideo) {
              //   this.$message.error('上传视频只能是 mp4 格式!');
              // }
              if (limitVideo && isvideo != -1) {
                this.fullPageLoading = true;
                resolve(true);
              } else {
                reject(false);
              }
            } else if (videoThree != -1 && videoFifteen == -1) {
              if (!limitVideo) {
                this.$message.error("上传视频时长只能是三分钟以内!");
              }
              // if (!isvideo) {
              //   this.$message.error('上传视频只能是 mp4 格式!');
              // }
              if (limitVideo && isvideo != -1) {
                this.fullPageLoading = true;
                resolve(true);
              } else {
                reject(false);
              }
            } else if (videoFifteen != -1) {
              if (!limitVideo) {
                this.$message.error("上传视频时长只能是十五分钟以内!");
              }
              // if (!isvideo) {
              //   this.$message.error('上传视频只能是 mp4 格式!');
              // }
              if (limitVideo && isvideo != -1) {
                this.fullPageLoading = true;
                resolve(true);
              } else {
                reject(false);
              }
            } else {
              this.$message.error(
                  "上传失败，请上传 " +
                  (imgSingle != -1 || imgMultiple != -1
                      ? "png, jpg, jpeg, gif, "
                      : "") +
                  (isPPT != -1 ? "ppt, pptx, " : "") +
                  (isWORD != -1 ? "doc, docx, " : "") +
                  (isEXCEL != -1 ? "xls, xlsx, " : "") +
                  (isPDF != -1 ? "pdf " : "") +
                  "格式文件"
              );
              reject(false);
            }
          } else if (
              isPPTType != -1 ||
              isWORDType != -1 ||
              isEXCELType != -1 ||
              isPDFType != -1
          ) {
            if (
                limitPPTType ||
                limitWORDType ||
                limitEXCELType ||
                limitPDFType
            ) {
              this.fullPageLoading = true;
              resolve(true);
            } else if (
                isPPTType != -1 ||
                isWORDType != -1 ||
                isEXCELType != -1 ||
                isPDFType != -1
            ) {
              this.$message.error(
                  "上传失败，请上传 " +
                  (isPPT != -1 ? "ppt, pptx, " : "") +
                  (isWORD != -1 ? "doc, docx, " : "") +
                  (isEXCEL != -1 ? "xls, xlsx, " : "") +
                  (isPDF != -1 ? "pdf " : "") +
                  " 格式文件"
              );
              reject(false);
            } else {
              this.$message.error(
                  "上传失败，请上传 " +
                  (imgSingle != -1 || imgMultiple != -1
                      ? "png, jpg, jpeg, gif, "
                      : "") +
                  (videoOne != -1 || videoThree != -1 || videoFifteen != -1
                      ? "mp4, mov, rmvb, flv, avi, mpg "
                      : "") +
                  "格式文件"
              );
              reject(false);
            }
          } else {
            this.$message.error(
                "上传失败，请上传 " +
                (imgSingle != -1 || imgMultiple != -1
                    ? "png, jpg, jpeg, gif, "
                    : "") +
                (videoOne != -1 || videoThree != -1 || videoFifteen != -1
                    ? "mp4, mov, rmvb, flv, avi, mpg, "
                    : "") +
                (isPPT != -1 ? "ppt, pptx, " : "") +
                (isWORD != -1 ? "doc, docx, " : "") +
                (isEXCEL != -1 ? "xls, xlsx, " : "") +
                (isPDF != -1 ? "pdf " : "") +
                "格式文件"
            );
            reject(false);
          }
        } else {
          this.fullPageLoading = true;
          resolve(true);
        }
      });
    },
    //获取视频时长
    getVideoTime(file) {
      return new Promise((resolve, reject) => {
        //获取视频或者音频时长
        let fileurl = URL.createObjectURL(file);
        //经测试，发现audio也可获取视频的时长
        let audioElement = new Audio(fileurl);
        audioElement.addEventListener("loadedmetadata", function (_event) {
          resolve(audioElement.duration);
        });
      });
    },
    //处理附件事件
    handleAttachFileFun(type, fileInfo, fileIndex, optionIndex, listIndex) {
      if (
          (!(
                  (this.jumpType == "headquarters" && fileInfo.level == "1") ||
                  (this.jumpType == "company" && fileInfo.level == "2") ||
                  (this.jumpType == "school" && fileInfo.level == "3")
              ) &&
              type == "del") ||
          (!fileInfo.attachmentId && type != "del")
      ) {
        return;
      }
      let url = "/supervise/attachment/downloadFile";
      switch (type) {
        case "view":
          let index = ["png", "jpg", "jpeg", "gif"].findIndex((info) => {
            return info == fileInfo.attachmentType;
          });
          let videoIndex = [
            "mp4",
            "mov",
            "rmvb",
            "flv",
            "avi",
            "mpg",
          ].findIndex((info) => {
            return info == fileInfo.attachmentType;
          });
          if (fileInfo.pdfPath || "pdf" == fileInfo.attachmentType || "PDF" == fileInfo.attachmentType) {
            let data = {
              id: fileInfo.attachmentId,
              type: 2,
              flag: 1,
            };
            let type = "get";
            this.onlinePreviewVisible = true;
            this.fileAttachment = {
              previewType: "pdf",
              url: fileInfo.pdfPath || fileInfo.path,
              attachment_name: fileInfo.attachmentName,
              attachment_type: fileInfo.attachmentType,
              title: fileInfo.attachmentName,
            };
          } else if (videoIndex != -1) {
            this.onlinePreviewVisible = true;
            this.$nextTick(() => {
              this.fileAttachment = {
                previewType: "video",
                url: fileInfo.path,
                attachment_name: fileInfo.attachmentName,
                attachment_type: fileInfo.attachmentType,
                title: fileInfo.attachmentName,
              };
            });
          } else if (index != -1) {
            this.viewUrl = fileInfo.path;
            this.viewUrlList = [fileInfo.path];
            this.$nextTick(() => {
              this.$refs.viewImg.showViewer = true;
            });
          }
          break;
        case "download":
          let noDownloadIndex = [
            "png",
            "jpg",
            "jpeg",
            "gif",
            "mp4",
            "mov",
            "rmvb",
            "flv",
            "avi",
            "mpg",
          ].findIndex((info) => {
            return info == fileInfo.attachmentType;
          });
          if (fileInfo.path && noDownloadIndex === -1) {
            let data = {
              id: fileInfo.attachmentId,
              type: 1,
              flag: 0,
            };
            let type = "get";
            downloadFile({url, data, type, prevent: false});
          } else {
            this.$message.error("视频和图片不支持下载");
          }
          break;
        case "print":
          break;
        case "del":
          let optionsInfo = JSON.parse(
              JSON.stringify(
                  this.projectContentList[listIndex].optionsList[optionIndex]
              )
          );
          optionsInfo.attachmentList.splice(fileIndex, 1);
          this.$set(
              this.projectContentList[listIndex].optionsList,
              optionIndex,
              optionsInfo
          );
          this.$set(
              this.requestData[optionsInfo.firstLevelIndex].optionsList,
              optionsInfo.fourLevelIndex,
              optionsInfo
          );
          break;
        default:
          break;
      }
    },
    //关闭预览
    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },
    //下载历史评分附件
    downloadScoreFile(row) {
      let url = "/supervise/attachment/downloadFile";
      let noDownloadIndex = [
        "png",
        "jpg",
        "jpeg",
        "gif",
        "mp4",
        "mov",
        "rmvb",
        "flv",
        "avi",
        "mpg",
      ].findIndex((info) => {
        return info == row.attachmentType;
      });
      let index = ["png", "jpg", "jpeg", "gif"].findIndex((info) => {
        return info == row.attachmentType;
      });
      let videoIndex = ["mp4", "mov", "rmvb", "flv", "avi", "mpg"].findIndex(
          (info) => {
            return info == row.attachmentType;
          }
      );
      if (row.path && noDownloadIndex === -1) {
        let data = {
          id: row.attachmentId,
          type: 1,
          flag: 0,
        };
        let type = "get";
        downloadFile({url, data, type, prevent: false});
      } else if (videoIndex != -1) {
        this.onlinePreviewVisible = true;
        this.$nextTick(() => {
          this.fileAttachment = {
            previewType: "video",
            url: row.path,
            attachment_name: row.attachmentName,
            attachment_type: row.attachmentType,
            title: row.attachmentName,
          };
        });
      } else if (index != -1) {
        this.viewUrl = row.path;
        this.viewUrlList = [row.path];
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true;
        });
      } else {
        this.$message.error("暂不支持下载或预览");
      }
    },
    //改变督导内容
    handleSelectSupervise(val) {
      this.projectContent = val;
      this.getShowData();
    },
    //头部选项变化
    handleListChange(val) {
      // let valString = val.join(",")
      // if(valString.indexOf("1") != -1){
      //   this.projectContentList.map((item) => {
      //     item.optionsList.map((info) => {
      //       info.isShowScoreList = true
      //     })
      //   })
      //   this.requestData.map((item) => {
      //     item.optionsList.map((info) => {
      //       info.isShowScoreList = true
      //     })
      //   })
      // } else {
      //   this.projectContentList.map((item) => {
      //     item.optionsList.map((info) => {
      //       info.isShowScoreList = false
      //     })
      //   })
      //   this.requestData.map((item) => {
      //     item.optionsList.map((info) => {
      //       info.isShowScoreList = false
      //     })
      //   })
      // }

      // if(valString.indexOf("2") != -1){
      //   this.getShowData()
      // } else {
      //   this.projectContentList = JSON.parse(JSON.stringify(this.requestData))
      // }
      this.getShowData();
    },
    //历史评分展开收起
    handleScoreShow(optionInfo, optionIndex, listIndex) {
      let resultInfo = JSON.parse(JSON.stringify(optionInfo));
      resultInfo.isChangeShow = !resultInfo.isChangeShow;
      this.$set(
          this.projectContentList[listIndex].optionsList,
          optionIndex,
          resultInfo
      );
      this.$set(
          this.requestData[resultInfo.firstLevelIndex].optionsList,
          resultInfo.fourLevelIndex,
          resultInfo
      );
    },
    //加入亮点库事件
    handleLightspot(optionInfo, optionIndex, listIndex) {
      let selectOptionInfo = optionInfo.options.find((result) => {
        return result.id == optionInfo.chooseOptions;
      });
      let params = {
        templateId: this.taskInfo.templateId,
        templateName: this.taskInfo.templateName,
        supervisionTopic: this.projectContentList[listIndex].supervisionTopic,
        orgId: "",
        orgName: "",
        parkId: "",
        parkName: "",
        level:
            this.jumpType == "headquarters"
                ? "1"
                : this.jumpType == "company"
                    ? "2"
                    : "",
        libraryType: "1",
        score: optionInfo.selectOptionsScore,
        scoreId: optionInfo.chooseOptions,
        orderId: this.projectContentList[listIndex].orderId,
        projectId: optionInfo.id,
        projectName: optionInfo.projectName,
        highlights: selectOptionInfo.projectName,
        joinReason: "",
        userName: this.userInfo.userName,
        optionIndex: optionIndex,
        listIndex: listIndex,
        optionInfo: optionInfo,
      };
      this.lightspotDialogVisible = true;
      this.$nextTick(() => {
        this.lightspotData = {...params};
      });
    },
    //加入亮点库返回事件
    lightspotReturnFun(data) {
      if (data.flag) {
        let reason = data.reason;
        let params = JSON.parse(JSON.stringify(this.lightspotData));
        delete params.optionIndex;
        delete params.listIndex;
        delete params.optionInfo;
        params.joinReason = reason;
        this.$api
            .saveLightspot(params)
            .then((res) => {
              if (res.data.code == 0) {
                this.lightspotDialogVisible = false;
                let resultInfo = JSON.parse(
                    JSON.stringify(this.lightspotData.optionInfo)
                );
                resultInfo.isLibrary = true;
                this.$set(
                    this.projectContentList[resultInfo.firstLevelIndex].optionsList,
                    resultInfo.fourLevelIndex,
                    resultInfo
                );
                this.$set(
                    this.requestData[resultInfo.firstLevelIndex].optionsList,
                    resultInfo.fourLevelIndex,
                    resultInfo
                );
                this.$nextTick(() => {
                  this.lightspotData = {};
                });
              } else {
                this.$message.error(res.data.msg);
                this.$refs.lightspotDialog.lightspotButtonloading = false;
              }
            })
            .catch(() => {
              this.$refs.lightspotDialog.lightspotButtonloading = false;
            });
      } else {
        this.lightspotDialogVisible = false;
        this.$nextTick(() => {
          this.lightspotData = {};
        });
      }
    },
    //选项变化事件
    handleOptionsChange(val, optionInfo, optionIndex, listIndex) {
      let options = optionInfo.options.find((info) => {
        return info.id == val;
      });
      let resultInfo = JSON.parse(JSON.stringify(optionInfo));
      if (this.jumpType == "company" || this.jumpType == "headquarters") {
        if (options) {
          if (
              Number(options.score) === resultInfo.maxScore &&
              options.scoreType == "1"
          ) {
            resultInfo.isTotalScore = true;
          } else {
            resultInfo.isTotalScore = false;
          }
        }
      }
      resultInfo.options.map((item) => {
        debugger;
        if (item.id == val) {
          item.isCheck = "1";
        } else {
          item.isCheck = "0";
        }
      });
      resultInfo.selectOptionsScore = options.score;
      this.$set(
          this.projectContentList[listIndex].optionsList,
          optionIndex,
          resultInfo
      );
      this.$set(
          this.requestData[resultInfo.firstLevelIndex].optionsList,
          resultInfo.fourLevelIndex,
          resultInfo
      );
      this.$nextTick(() => {
        let flag = this.projectContentList[listIndex].optionsList.every(
            (optionsInfo) => {
              return optionsInfo.chooseOptions !== "";
            }
        );
        if (flag) {
          let chooseIndex = this.projectContentOptions.findIndex(
              (chooseInfo) => {
                return chooseInfo.id == this.projectContentList[listIndex].id;
              }
          );
          if (chooseIndex != -1) {
            let info = JSON.parse(
                JSON.stringify(this.projectContentOptions[chooseIndex])
            );
            info.isFinish = true;
            this.$set(this.projectContentOptions, chooseIndex, info);
          }
        }
        this.$nextTick(() => {
          let list = this.projectContentOptions.filter((optionsInfo) => {
            return optionsInfo.id !== "";
          });
          let optionsAll = list.every((chooseInfo) => {
            return chooseInfo.id !== "" && chooseInfo.isFinish === true;
          });
          let allInfo = JSON.parse(
              JSON.stringify(this.projectContentOptions[0])
          );
          if (optionsAll) {
            allInfo.isFinish = true;
          } else {
            allInfo.isFinish = false;
          }
          this.$set(this.projectContentOptions, 0, allInfo);
        });
      });
    },
    //评语变化事件
    handleCommentChange(val, optionInfo, optionIndex, listIndex) {
      let resultInfo = JSON.parse(JSON.stringify(optionInfo));
      resultInfo.comment = val;
      this.$set(
          this.projectContentList[listIndex].optionsList,
          optionIndex,
          resultInfo
      );
      this.$set(
          this.requestData[resultInfo.firstLevelIndex].optionsList,
          resultInfo.fourLevelIndex,
          resultInfo
      );
    },
    //获取整改项数据
    getShowData() {
      let list = [];
      let allList = [];
      let resultList = [];
      let resultAllList = [];
      if (this.projectContent) {
        this.requestData.map((item) => {
          if (item.id == this.projectContent) {
            list.push(item);
          }
        });
        allList = JSON.parse(JSON.stringify(this.requestData));
      } else {
        list = JSON.parse(JSON.stringify(this.requestData));
        allList = JSON.parse(JSON.stringify(this.requestData));
      }
      let showHistory = this.selectCheck.findIndex((checkInfo) => {
        return checkInfo == "1";
      });
      let showRectify = this.selectCheck.findIndex((checkInfo) => {
        return checkInfo == "2";
      });
      console.log(this.requestData);
      if (showHistory != -1 && showRectify == -1) {
        list.map((item) => {
          item.optionsList.map((info) => {
            info.isShowScoreList = true;
          });
          let resultInfo = JSON.parse(JSON.stringify(item));
          resultList.push(resultInfo);
        });
        allList.map((item) => {
          item.optionsList.map((info) => {
            info.isShowScoreList = true;
          });
        });
      } else if (showHistory == -1 && showRectify != -1) {
        list.map((item) => {
          let rectifyNum = 0;
          let rectifyList = [];
          let resultInfo = JSON.parse(JSON.stringify(item));
          item.optionsList.map((info) => {
            if (info.isRectify) {
              rectifyNum++;
              rectifyList.push(info);
            }
            info.isShowScoreList = false;
          });
          if (
              rectifyNum != 0 &&
              this.taskInfo.orderStatus &&
              this.taskInfo.orderStatus != "1"
          ) {
            resultInfo.optionsList = rectifyList;
            resultList.push(resultInfo);
          }
        });
        allList.map((item) => {
          item.optionsList.map((info) => {
            info.isShowScoreList = false;
          });
        });
      } else if (showHistory != -1 && showRectify != -1) {
        list.map((item) => {
          let rectifyNum = 0;
          let rectifyList = [];
          let resultInfo = JSON.parse(JSON.stringify(item));
          item.optionsList.map((info) => {
            if (info.isRectify) {
              rectifyNum++;
              rectifyList.push(info);
            }
            info.isShowScoreList = true;
          });
          if (
              rectifyNum != 0 &&
              this.taskInfo.orderStatus &&
              this.taskInfo.orderStatus != "1"
          ) {
            resultInfo.optionsList = rectifyList;
            resultList.push(resultInfo);
          }
        });
        allList.map((item) => {
          item.optionsList.map((info) => {
            info.isShowScoreList = false;
          });
        });
      } else {
        list.map((item) => {
          item.optionsList.map((info) => {
            info.isShowScoreList = false;
          });
          let resultInfo = JSON.parse(JSON.stringify(item));
          resultList.push(resultInfo);
        });
        allList.map((item) => {
          item.optionsList.map((info) => {
            info.isShowScoreList = false;
          });
        });
      }

      console.log(resultList);

      this.projectContentList = resultList;
      this.requestData = allList;
    },
    //取消
    onCancel() {
      this.$router.back(-1);
    },
    //驳回园校
    onReturn() {
      // if(!this.updateVal){
      //   this.$message.error("请标记园校需要整改事项");
      //   return
      // }
      if (this.updateValArr.length == 0) {
        this.$message.error("请标记园校需要整改事项");
        return;
      }
      let validateFlag = this.validateCheckOptions();
      let validateFileFlag = this.validateCheckFile();
      if (validateFlag) {
        // if(validateFileFlag){
        this.buttonLoading = true;
        this.submitData("returnSchool");
        // } else {
        //   this.$message.error("驳回失败，您还有题目的附件没有上传，暂不能提交");
        // }
      } else {
        this.$message.error("驳回失败，您还有题目没有完成，暂不能提交");
      }
    },
    //提交
    onSubmit() {
      // if(this.updateVal){
      //   this.$message.error("存在整改事项只能驳回操作");
      //   return
      // }
      if (this.updateValArr.length > 0) {
        this.$message.error("存在整改事项只能驳回操作");
        return;
      }
      this.savePopover = false;
      let validateFlag = this.validateCheckOptions();
      let validateFileFlag = this.validateCheckFile();
      if (validateFlag) {
        if (validateFileFlag) {
          this.buttonLoading = true;
          this.submitData("submit");
        } else {
          this.$message.error("提交失败，您还有题目的附件没有上传，暂不能提交");
        }
      } else {
        this.$message.error("提交失败，您还有题目没有完成，暂不能提交");
      }
    },
    //保存
    onSave() {
      this.savePopover = false;
      this.buttonLoading = true;
      this.submitData("save");
    },
    //处理展示保存弹窗显隐
    handleSavePopoverShow() {
      let validateFlag = this.validateCheckOptions();
      let validateFileFlag = this.validateCheckFile();
      if (validateFlag) {
        // if(!validateFileFlag){
        //   this.savePopover = false
        //   this.$message.error("保存失败，您还有题目的附件没有上传，暂不能提交");
        // }
      } else {
        this.savePopover = false;
        this.onSave();
        // this.$message.error("保存失败，您还有题目没有完成，暂不能提交");
      }
    },
    //提交数据
    submitData(type) {
      let resultList = [];
      this.requestData.map((item) => {
        item.optionsList.map((info) => {
          if (info.chooseOptions !== "") {
            let resultInfo = {
              orderId: item.orderId,
              taskId: item.taskId,
              templateId: this.taskInfo.templateId,
              projectId: info.id,
              scoreId: info.chooseOptions,
              nodeName:
                  this.jumpType == "headquarters"
                      ? "3"
                      : this.jumpType == "company"
                          ? "2"
                          : this.jumpType == "school"
                              ? "1"
                              : "",
              operate: this.userInfo.userName,
              score: info.selectOptionsScore,
              comment: info.comment,
              isRectify:
                  type == "returnSchool"
                      ? info.chooseRectify
                          ? "1"
                          : "0"
                      : type == "save" && info.isRectify
                          ? "1"
                          : "0",
              isLibrary: info.isLibrary ? "1" : "0",
              status:
                  type == "save"
                      ? "0"
                      : type == "returnSchool"
                          ? this.jumpType == "headquarters"
                              ? "5"
                              : this.jumpType == "company"
                                  ? "4"
                                  : ""
                          : "1",
              attachmentList: info.attachmentList,
            };
            resultList.push(resultInfo);
          }
        });
      });
      this.$api
          .saveAgencyTaskScore(resultList)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message:
                    type == "save"
                        ? "保存成功!"
                        : type == "returnSchool"
                            ? "驳回园校成功!"
                            : "提交成功!",
                duration: 2000,
                onClose: () => {
                  this.buttonLoading = false;
                  if (type == "save") {
                    // this.getShowData()
                    window.location.reload();
                  } else {
                    this.onCancel();
                  }
                },
              });
            } else {
              this.buttonLoading = false;
              if (res.data.msg) {
                this.$message.error(res.data.msg);
              }
            }
          })
          .catch(() => {
            this.buttonLoading = false;
          });
    },
    //校验是否全部题目提交选项
    validateCheckOptions() {
      let flag = false;
      let optionsNum = 0;
      let optionsLength = 0;
      this.requestData.map((item) => {
        optionsLength += item.optionsList.length;
      });
      this.requestData.map((item) => {
        item.optionsList.map((info) => {
          if (info.chooseOptions) {
            optionsNum++;
          }
        });
      });
      if (optionsNum < optionsLength) {
        flag = false;
      } else if (optionsNum == optionsLength) {
        flag = true;
      }

      return flag;
    },
    //校验是否需要必填的题目附件上传
    validateCheckFile() {
      let flag = false;
      let fileRequireNum = 0;
      let fileRequireLength = 0;
      this.requestData.map((item) => {
        item.optionsList.map((info) => {
          if (info.isRequired == "1") {
            fileRequireLength++;
          }
        });
      });
      this.requestData.map((item) => {
        item.optionsList.map((info) => {
          if (
              info.isRequired == "1" &&
              info.attachmentList &&
              info.attachmentList.length > 0
          ) {
            let findFileIndex = info.attachmentList.findIndex((fileInfo) => {
              return (fileInfo.level == this.jumpType) == "headquarters"
                  ? "1"
                  : this.jumpType == "company"
                      ? "2"
                      : "3";
            });
            if (findFileIndex != -1) {
              fileRequireNum++;
            }
          }
        });
      });
      if (fileRequireNum < fileRequireLength) {
        flag = false;
      } else if (fileRequireNum == fileRequireLength) {
        flag = true;
      }

      return flag;
    },
  },
  beforeDestroy() {
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: calc(100% - 64px);
  padding: 34px 30px 30px 30px;

  .header-title {
    width: 100%;
    color: #3d3e4f;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .return-button {
      &.el-button:focus {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }

      &.el-button:hover {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }

  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/ .el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .dashed-line {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #e2e7f0;
    margin: 40px 0;
  }

  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    min-height: 696px;

    .elInput {
      width: 100%;
    }

    .form-title {
      width: 100%;
      color: #3d3e4f;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }

    /deep/ .el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/ .el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }

    /deep/ .el-input--suffix .el-input__inner {
      padding-right: 30px;
    }

    /deep/ .el-input--suffix .el-input__inner,
    /deep/ .el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/ .el-textarea__inner {
      height: 80px;
    }

    /deep/ .el-switch__label.is-active {
      color: #010334;
    }

    /deep/ .el-switch__label {
      color: #010334;
    }

    /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #003685;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }

    /deep/ .el-checkbox__inner:hover {
      border-color: #003685 !important;
    }

    /deep/ .el-checkbox__label {
      color: #010334 !important;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 130px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .return {
      display: block;
      width: 137px;
      height: 40px;
      background: #c6000b;
      box-shadow: 0px 3px 10px rgba(198, 0, 11, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .save {
      display: block;
      width: 137px;
      height: 40px;
      background: #e7c69c;
      box-shadow: 0px 3px 10px rgba(231, 198, 156, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      margin-left: 20px;
    }

    /deep/ .el-button + .el-button {
      margin-left: 20px;
    }
  }

  /deep/ .el-table {
    // margin-top: 14px;
  }

  /deep/ .el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }

  /deep/ .el-table th:first-child > .cell {
    border-left: transparent;
  }

  .table-handle.file {
    display: block;
    margin-left: 0;
  }
}
</style>
